<template>
    <h2 class="kiu-grid-title" v-if="visible">
        <span class="kiu-grid-title__text"
            ><span v-if="needsPrefix" class="kiu-grid-title__prefix">Hotelgutscheine</span> <strong><slot /></strong
        ></span>
    </h2>
</template>

<script>
export default {
    name: 'KIUGridTitle',
    computed: {
        titleText() {
            return (this.$getSafe(this.$slots.default, '[0].data.domProps.innerHTML') || '').trim();
        },
        visible() {
            return this.titleText !== '';
        },
        needsPrefix() {
            return !this.titleText.startsWith('Hotelgutscheine') && this.titleText !== '';
        }
    }
};
</script>

<style scoped lang="scss">
.kiu-grid-title {
    position: relative;
    text-align: center;
    line-height: 1.3em;
    margin-bottom: min(4%, 1.875rem);
    font-size: clamp(1.5rem, 1.4167rem + 0.4167vw, 1.75rem);

    &::before {
        background: var(--body-color);
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        position: absolute;
        top: 50%;
        left: 0;
    }

    &__prefix {
        font-weight: 300;
    }

    &__text {
        padding: 0 20px;
        z-index: 1;
        background-color: var(--white);
        position: relative;
        font-weight: bold;
        display: inline-block; //mobile horizontal line and text centering
    }
}
</style>
