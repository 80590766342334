import { render, staticRenderFns } from "./KIUGridTitle.vue?vue&type=template&id=427c7af5&scoped=true"
import script from "./KIUGridTitle.vue?vue&type=script&lang=js"
export * from "./KIUGridTitle.vue?vue&type=script&lang=js"
import style0 from "./KIUGridTitle.vue?vue&type=style&index=0&id=427c7af5&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "427c7af5",
  null
  
)

export default component.exports